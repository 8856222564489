import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import $ from 'jquery'

export default class Navigation extends Component {
	render() {
		return (
			<React.Fragment>
				<li>
				<button onClick={
					() => {
						/* eslint-disable eqeqeq */
						const wCarousel = $('.work-carousel');
						const iWrapper = $('.multi-page .inner-wrapper');

						browserHistory.push('/');

						if(this.props.state.first == false){
							if(this.props.stateIndex.slideIndex.index == "0"){
								this.props.fullpageApi.moveSlideLeft()
							}else if(this.props.stateIndex.slideIndex.index == "1"){
								this.props.fullpageApi.moveSlideRight()
							}else {
								// do nothing
							}
							iWrapper.removeClass('about-2 services serv2 serv3 tech tech2 tech3 tech4 tech5 tech6');
							wCarousel.removeClass('s2 s3').addClass('s1');
							$('#move-bg').removeClass('active push');
							$('body').removeClass('dark');
						}else {
							// do nothing
						}

						$('.top-nav').addClass('disabled');
						$('.bottom-nav').removeClass('disabled');
						$('.sidebar .info-section').removeClass('active').text('work');
						$('.pagination ul').removeClass().addClass('home');

						const toggler = document.querySelector('.menu__toggler');
						const menu    = document.querySelector('.menu');

						if (menu.classList.contains('active')) {
							toggler.classList.toggle('active');
							menu.classList.toggle('active');
						}
						
					}
				}>
				Home
				</button>
				</li>
				<li>
				<button onClick={
					() => {
						const wCarousel = $('.work-carousel');
						const iWrapper = $('.multi-page .inner-wrapper');
						const bg = $('#move-bg');

						browserHistory.push('/work'); 

						if(this.props.state.first == true){
							this.props.firstState();
							this.props.fullpageApi.moveSlideLeft();
							bg.addClass('active');
						}else {
							if(this.props.stateIndex.slideIndex.index == "2"){
								this.props.fullpageApi.moveSlideLeft();
								bg.addClass('active');
							}else if(this.props.stateIndex.slideIndex.index == "0"){
								this.props.fullpageApi.moveSlideRight();
								bg.removeClass('push');
								wCarousel.removeClass('s2 s3').addClass('s1');
								iWrapper.removeClass('about-2 services serv2 serv3 tech tech2 tech3 tech4 tech5 tech6');
							}else {
								// do nothing
							}
						}

						$('body').addClass('dark');
						$('.top-nav, .bottom-nav').removeClass('disabled');
						$('.sidebar .info-section').addClass('active').text('work');
						$('.pagination ul').removeClass().addClass('work');

						const toggler = document.querySelector('.menu__toggler');
						const menu    = document.querySelector('.menu');

						if (menu.classList.contains('active')) {
							toggler.classList.toggle('active');
							menu.classList.toggle('active');
						}
						
					}
				}>
				Work
				</button>
				</li>
				<li>
				<button onClick={
					() => {
						const wCarousel = $('.work-carousel');
						const iWrapper = $('.multi-page .inner-wrapper');
						const bg = $('#move-bg');

						browserHistory.push('/about');

						if(this.props.state.first == true){
							this.props.firstState();
							this.props.fullpageApi.moveSlideRight();
							bg.addClass('active push');
							wCarousel.removeClass('s1').addClass('s3');
						}else {
							if(this.props.stateIndex.slideIndex.index == "1" || this.props.stateIndex.slideIndex.index == "2"){
								if(this.props.stateIndex.slideIndex.index == "1"){
									this.props.fullpageApi.moveSlideLeft();
								}else {
									this.props.fullpageApi.moveSlideRight();
								}
								bg.addClass('active push');
								wCarousel.removeClass('s1 s2').addClass('s3');
							}else {
								if(iWrapper.hasClass('services')){
									iWrapper.removeClass('about-2 services serv2 serv3 tech tech2 tech3 tech4 tech5 tech6');
								}
							}
						}

						$('body').addClass('dark');
						$('.top-nav, .bottom-nav').removeClass('disabled');
						$('.sidebar .info-section').addClass('active').text('about');
						$('.pagination ul').removeClass().addClass('about');

						const toggler = document.querySelector('.menu__toggler');
						const menu    = document.querySelector('.menu');

						if (menu.classList.contains('active')) {
							toggler.classList.toggle('active');
							menu.classList.toggle('active');
						}
					}
				}>
				About
				</button>
				</li>
				<li>
				<button onClick={
					() => {
						const wCarousel = $('.work-carousel');
						const iWrapper = $('.multi-page .inner-wrapper');
						const bg = $('#move-bg');

						browserHistory.push('/services');

						if(this.props.state.first == true){
							this.props.firstState();
							this.props.fullpageApi.moveSlideRight();
							bg.addClass('active push');
							wCarousel.removeClass('s1').addClass('s3');
							iWrapper.addClass('about-2 services');
						}else {
							if(this.props.stateIndex.slideIndex.index == "1" || this.props.stateIndex.slideIndex.index == "2"){
								if(this.props.stateIndex.slideIndex.index == "1"){
									this.props.fullpageApi.moveSlideLeft();
								}else {
									this.props.fullpageApi.moveSlideRight();
								}
								bg.addClass('active push');
								wCarousel.removeClass('s1 s2').addClass('s3');
								iWrapper.addClass('about-2 services');
							}else {
								if(iWrapper.hasClass('serv2')){
									iWrapper.removeClass('serv2 serv3 tech tech2 tech3 tech4 tech5 tech6');
								}else {
									iWrapper.addClass('about-2 services')
								}
							}
						}

						$('body').removeClass('dark');
						$('.top-nav, .bottom-nav').removeClass('disabled');
						$('.sidebar .info-section').addClass('active').text('services');
						$('.pagination ul').removeClass().addClass('services');

						const toggler = document.querySelector('.menu__toggler');
						const menu    = document.querySelector('.menu');

						if (menu.classList.contains('active')) {
							toggler.classList.toggle('active');
							menu.classList.toggle('active');
						}
						
					}
				}>
				Services
				</button>
				</li>
				<li>
				<button onClick={
					() => {
						const wCarousel = $('.work-carousel');
						const iWrapper = $('.multi-page .inner-wrapper');
						const bg = $('#move-bg');

						browserHistory.push('/technologies');

						if(this.props.state.first == true){
							this.props.firstState();
							this.props.fullpageApi.moveSlideRight();
							bg.addClass('active push');
							wCarousel.removeClass('s1').addClass('s3');
							iWrapper.addClass('about-2 services serv2 serv3 tech');
						}else {
							if(this.props.stateIndex.slideIndex.index == "1" || this.props.stateIndex.slideIndex.index == "2"){
								if(this.props.stateIndex.slideIndex.index == "1"){
									this.props.fullpageApi.moveSlideLeft();
								}else {
									this.props.fullpageApi.moveSlideRight();
								}
								bg.addClass('active push');
								wCarousel.removeClass('s1 s2').addClass('s3');
								iWrapper.addClass('about-2 services serv2 serv3 tech');
							}else {
								if(iWrapper.hasClass('tech2')){
									iWrapper.removeClass('tech2 tech3 tech4 tech5 tech6');
								}else {
									iWrapper.addClass('about-2 services serv2 serv3 tech')
								}
							}
						}

						$('body').removeClass('dark');
						$('.top-nav, .bottom-nav').removeClass('disabled');
						$('.sidebar .info-section').addClass('active').text('technologies');
						$('.pagination ul').removeClass().addClass('technologies');

						const toggler = document.querySelector('.menu__toggler');
						const menu    = document.querySelector('.menu');

						if (menu.classList.contains('active')) {
							toggler.classList.toggle('active');
							menu.classList.toggle('active');
						}
						
					}
				}>
				Technologies
				</button>
				</li>
			</React.Fragment>
		)
	}
}
