import React, { Component } from 'react'
import $ from 'jquery'
import about from '../assets/mock.jpg'

export default class About extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="about-1">
					<div className="wrapper">
						<div className="inner">
							<div className="heading">
								<h2>Specializing in Online <br/>Web Design and <br/>Development</h2>
							</div>
							<div className="sub-wrap">
								<div className="sub">
									<h3><span>WYEL</span> is an online media design and development agency</h3>
								</div>
								<div className="desc">
									<h5>Passion is our virtue</h5>
									<p>We treat every project with full commitments and strive to exceed clients expectations. May you be looking to build a state-of-the-art online application or simply a tight budgeted application, we always endeavour to deliver you optimum results.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="about-2">
					<div className="img-wrap">
						<img src={about} alt="wyel" />
					</div>
					<div className="content-wrap">
						<h3>We offer a one stop service for all your online needs</h3>
						<p>Our work consist of web design, content management system, e-commerce application, mobile application, application maintenance and consulting.</p>
						<button className="arrow" onClick={ () => {
							$('.multi-page .inner-wrapper').addClass('services');
							$('body').removeClass('dark');
							$('.pagination ul').removeClass().addClass('services');
						} }>Our Services</button>
					</div>
				</div>
			</React.Fragment>
		)
	}
}
