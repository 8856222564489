import React, { Component } from 'react'
import $ from 'jquery'
import ReactFullpage from '@fullpage/react-fullpage'

import PrevNext from './components/PrevNext'
import Navigation from './components/Navigation'

import Home from './components/Home'
import Work from './components/Work'
import About from './components/About'
import Services from './components/Services'
import Technologies from './components/Technologies'
import Contact from './components/Contact'

import portrait from './assets/portrait.png'
import landscape from './assets/landscape.png'

import logo from './assets/logo-fix.png'
import logo2 from './assets/logo-white.png'

export default class App extends Component {

	state = {
		first: true,
		workCarousel: "0"
	}

	changeState(){
		this.setState({
			first: false,
		})
	}
	
	// fake authentication Promise
	authenticate(){
		return new Promise(resolve => setTimeout(resolve, 3000)) // 3 seconds
	}

	componentDidMount = () => {

		this.authenticate().then(() => {
			const ele = document.getElementById('ipl-progress-indicator')
			if(ele){
			  // fade out
			  ele.classList.add('available')
			  setTimeout(() => {
				// remove from DOM
				ele.outerHTML = ''
			  }, 3000)
			}
		});

		let pathname = window.location.pathname.split('/')[1];
		const wCarousel = $('.work-carousel');
		const iWrapper = $('.multi-page .inner-wrapper');
		const bg = $('#move-bg');
		/* eslint-disable no-undef */
		if(pathname === 'work'){
			$('body').addClass('dark');
			$('.top-nav').removeClass('disabled');
			$('.info-section').addClass('active');
			$('.pagination ul').removeClass().addClass('work');
			$('.sidebar .info-section').addClass('active').text('work');
			$('.pagination ul').removeClass().addClass('work');
		}else if(pathname === 'about'){
			$('body').addClass('dark');
			$('.pagination ul').removeClass().addClass('about');
			$('.sidebar .info-section').addClass('active').text('about');
			$('.pagination ul').removeClass().addClass('about');
		}else if(pathname === 'services'){
			$('.pagination ul').removeClass().addClass('services');
			$('.sidebar .info-section').addClass('active').text('services');
			$('.pagination ul').removeClass().addClass('services');
			iWrapper.addClass('about-2 services');
		}else if(pathname === 'technologies'){
			$('.pagination ul').removeClass().addClass('technologies');
			$('.sidebar .info-section').addClass('active').text('technologies');
			$('.pagination ul').removeClass().addClass('technologies');
			iWrapper.addClass('about-2 services serv2 serv3 tech');
		}else {
			// do nothing
		}
		if (pathname === 'work'||pathname === 'about'||pathname === 'services'||pathname === 'technologies') {
			this.setState({first: false});
			$('.top-nav, .bottom-nav').removeClass('disabled');
			if (pathname==='work') {
				fullpage_api.moveSlideLeft();
			}else {
				fullpage_api.moveSlideRight();
				bg.addClass('active push');
				wCarousel.removeClass('s1').addClass('s3');
			}
		}else {
			// do nothing
		}
		/* eslint-enable */

		window.onload=function(){

			const toggler = document.querySelector('.menu__toggler');
			const menu    = document.querySelector('.menu');

			toggler.addEventListener('click', () => {
				toggler.classList.toggle('active');
				menu.classList.toggle('active');
			});

			let countX = $(".carousel-left .carousel-item").length;
			$('.carousel-left').addClass('list-'+countX);
			
		}
	}

	onLeave(origin, destination, direction) {

		// console.log("Leaving section " + origin.index);

		const toggler = document.querySelector('.menu__toggler');
		const menu    = document.querySelector('.menu');

		if (menu.classList.contains('active')) {
			toggler.classList.toggle('active');
			menu.classList.toggle('active');
		}
		
	}

	onSlideLeave(section, origin, destination, direction) {
		
		// console.log('origin: '+origin.index+'  desti: '+destination.index+'   section: '+section.index);

		/* eslint-disable eqeqeq */
		if (origin.index == "2" && direction == "left") {
			$('#move-bg').addClass('active');
		} else if(origin.index == "1" && direction == "right"){
			$('#move-bg').removeClass('active');
		} else if(origin.index == "1" && direction == "left"){
			$('#move-bg').addClass('push');
		} else if(origin.index == "0" && direction == "right"){
			$('#move-bg').removeClass('push');
		}else {
			// do nothing
		}
		

	}

	afterLoad() {
		let count = $(".carousel-left .carousel-item").length;
		this.setState({workCarousel: count})
		// console.log('after load')
	}

	render() {
		return (
			<div>

				<div className="landscape-mode force-mode">
					<div>
						<img src={landscape} alt="landscape" />
						<p>Please rotate your device to landscape mode</p>
					</div>
				</div>
				<div className="portrait-mode force-mode">
					<div>
						<img src={portrait} alt="portrait" />
						<p>Please rotate your device to portrait mode</p>
					</div>
				</div>
				
				<div className="sidebar">
					<div className="menu__toggler"><span></span></div>
					<div className="info-section">work</div>
					<div className="pagination">
						<ul className="home">
							<li className="one">01</li>
							<li className="line">&nbsp;</li>
							<li className="two">02</li>
							<li className="three">03</li>
							<li className="four">04</li>
							<li className="five">05</li>
						</ul>
					</div>
				</div>

				<div className="wrapper">
					
					<ReactFullpage

						//fullpage options
						licenseKey = {'OPEN-SOURCE-GPLV3-LICENSE'}
						scrollingSpeed = {1200}
						keyboardScrolling = {false}
						fixedElements = {'#logo, #prev-next, #fixed-menu, #move-bg, .force-mode'}
						normalScrollElements = {'.no-scroll'}
						anchor={['section1', 'section2']}
						afterLoad={this.afterLoad.bind(this)}
						// afterRender={this.afterRender.bind(this)}
						onLeave = {this.onLeave.bind(this)}
						onSlideLeave = {this.onSlideLeave.bind(this)}
						touchSensitivity = {500}
						
						render={({ state, fullpageApi }) => {
				
							return (
								<ReactFullpage.Wrapper>

									<PrevNext state={this.state} stateIndex={state} firstState={this.changeState.bind(this)} fullpageApi={fullpageApi}/>

									<div id="logo" className="no-scroll">
										<img className="logo1" src={logo} alt="wyel" />
										<img className="logo2" src={logo2} alt="wyel" />
									</div>

									<div id="fixed-menu" className="menu no-scroll">
										<div className="wrap">
											<div className="nav-wrap">
												
												<ul className="navigation">
													<Navigation state={this.state} stateIndex={state} firstState={this.changeState.bind(this)} fullpageApi={fullpageApi}/>
												</ul>
											</div>
											<Contact />
										</div>
									</div>

									<div id="move-bg">
										<div className="inner"></div>
									</div>

									<div className="section no-scroll">
										<div className="slide multi-page">
											<div className="inner-wrapper">
												<About />
												<Services />
												<Technologies />
											</div>
										</div>
										<Work />
										<Home firstState={this.changeState.bind(this)} fullpageApi={fullpageApi} />
									</div>
									{/* <div className="section no-scroll">
										<img className="img-slide-transform" src={img2} alt="lorem" />
									</div> */}
									
								</ReactFullpage.Wrapper>
							);
						}}
					/>

				</div>
			</div>
		)
	}
}