import React, { Component } from 'react'
import workEdge from '../assets/work_edge.png'
import workPan from '../assets/work_pan.png'
import workTlc from '../assets/work_tlc.png'

export default class Work extends Component {
	render() {
		return (
			<div className="slide">
				<div className="c-wrapper work-carousel s1">
					<div className="carousel-left">
						<div className="carousel-item">
							<div>
								<h4>Edge Food Theatre</h4>
								<ul>
									<li>CMS Development</li>
									<li>Website</li>
								</ul>
							</div>
						</div>
						<div className="carousel-item">
							<div>
								<h4>Pan Pacific Marketplace</h4>
								<ul>
									<li>CMS Development</li>
									<li>e-Commerce Development</li>
									<li>Collaboration with on23rd</li>
								</ul>
							</div>
						</div>
						<div className="carousel-item">
							<div>
								<h4>TLC</h4>
								<ul>
									<li>CMS Development</li>
									<li>e-Commerce Development</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="carousel-right">
						<div className="carousel-item">
							<img src={workEdge} alt="Edge Food Theatre" />
						</div>
						<div className="carousel-item">
							<img src={workPan} alt="pan pacific" />
						</div>
						<div className="carousel-item">
							<img src={workTlc} alt="TLC" />
						</div>
					</div>
				</div>
			</div>
		)
	}
}
