import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import $ from 'jquery'

export default class PrevNext extends Component {
	render() {
		return (
			<div id="prev-next" className="prev-next-navigation no-scroll">
				<div className="wrap">
					<button className="top-nav disabled" onClick={
						() => {
							/* eslint-disable eqeqeq */
							const wCarousel = $('.work-carousel');
							const iWrapper = $('.multi-page .inner-wrapper');

							console.log(this.props.state.first);

							if(this.props.state.first == false){
								if(this.props.stateIndex.slideIndex.index != "2"){
									if(this.props.stateIndex.slideIndex.index == "1"){
										if(wCarousel.hasClass('s1')){
											browserHistory.push('/');
											this.props.fullpageApi.moveSlideRight();
											$('body').removeClass('dark');
											$('.top-nav').addClass('disabled');
											$('.info-section').removeClass('active');
											$('.pagination ul').removeClass().addClass('home');
										}else if(wCarousel.hasClass('s2')){
											wCarousel.removeClass('s2').addClass('s1');
										}else if(wCarousel.hasClass('s3')){
											wCarousel.removeClass('s3').addClass('s2');
										// }else if(wCarousel.hasClass('s4')){
										// 	wCarousel.removeClass('s4').addClass('s3');
										}else {
											// do nothing
										}
									}else {
										if(this.props.stateIndex.slideIndex.index == "0"){
											if(iWrapper.hasClass('about-2')){
												if(iWrapper.hasClass('services')){
													if(iWrapper.hasClass('serv2')){
														if(iWrapper.hasClass('serv3')){
															if(iWrapper.hasClass('tech')){
																if(iWrapper.hasClass('tech2')){
																	if(iWrapper.hasClass('tech3')){
																		if(iWrapper.hasClass('tech4')){
																			if(iWrapper.hasClass('tech5')){
																				if(iWrapper.hasClass('tech6')){
																					iWrapper.removeClass('tech6');
																					$('.bottom-nav').removeClass('disabled');
																				}else {
																					iWrapper.removeClass('tech5');
																					$('.bottom-nav').removeClass('disabled');
																				}
																			}else {
																				iWrapper.removeClass('tech4');
																				$('.bottom-nav').removeClass('disabled');
																			}
																		}else {
																			iWrapper.removeClass('tech3');
																		}
																	}else {
																		iWrapper.removeClass('tech2');
																	}
																}else {
																	browserHistory.push('/services');
																	iWrapper.removeClass('tech');
																	$('.info-section').text('services');
																	$('.pagination ul').removeClass().addClass('services');
																}
															}else {
																iWrapper.removeClass('serv3')
															}
														}else {
															iWrapper.removeClass('serv2')
														}
													}else {
														browserHistory.push('/about');
														iWrapper.removeClass('services');
														$('body').addClass('dark');
														$('.info-section').text('about');
														$('.pagination ul').removeClass().addClass('about');
													}
												}else {
													iWrapper.removeClass('about-2')
												}
											}else {
												browserHistory.push('/work');
												this.props.fullpageApi.moveSlideRight();
												$('.info-section').text('work');
												$('.pagination ul').removeClass().addClass('work');
											}
										}else {
											browserHistory.push('/');
											this.props.fullpageApi.moveSlideRight()
										}
									}
								}
							}else {
								// do nothing
							}
						}
					}>
						up
					</button>
					<button className="bottom-nav" onClick={
						() => {

							const wCarousel = $('.work-carousel');
							const iWrapper = $('.multi-page .inner-wrapper');

							console.log(this.props.state.first);

							if(this.props.state.first == true){
								browserHistory.push('/work');
								this.props.firstState();
								this.props.fullpageApi.moveSlideLeft();
								$('body').addClass('dark');
								$('.top-nav').removeClass('disabled');
								$('.info-section').addClass('active');
								$('.pagination ul').removeClass().addClass('work');
							}else {
								if(this.props.stateIndex.slideIndex.index == "0"){
									if(iWrapper.hasClass('about-2')){
										if(iWrapper.hasClass('services')){
											if(iWrapper.hasClass('serv2')){
												if(iWrapper.hasClass('serv3')){
													if(iWrapper.hasClass('tech')){
														if(iWrapper.hasClass('tech2')){
															if(iWrapper.hasClass('tech3')){
																if(iWrapper.hasClass('tech4')){
																	if($(window).width()<=767){
																		if(iWrapper.hasClass('tech5')){
																			iWrapper.addClass('tech6');
																			$('.bottom-nav').addClass('disabled');
																		}else {
																			iWrapper.addClass('tech5')
																		}
																	}else {
																		// do nothing
																	}
																}else {
																	iWrapper.addClass('tech4');
																	if($(window).width()>767){
																		$('.bottom-nav').addClass('disabled');
																	}
																}
															}else {
																iWrapper.addClass('tech3');
															}
														}else {
															iWrapper.addClass('tech2');
															// if($(window).width()>767){
															// 	$('.bottom-nav').addClass('disabled');
															// }
														}
													}else {
														browserHistory.push('/technologies');
														iWrapper.addClass('tech');
														$('.info-section').text('technologies');
														$('.pagination ul').removeClass().addClass('technologies');
													}
												}else {
													iWrapper.addClass('serv3');
												}
											}else {
												iWrapper.addClass('serv2');
											}
										}else {
											browserHistory.push('/services');
											iWrapper.addClass('services');
											$('body').removeClass('dark');
											$('.info-section').text('services');
											$('.pagination ul').removeClass().addClass('services');
										}
									}else {
										iWrapper.addClass('about-2');
									}
								}else {
									if(this.props.stateIndex.slideIndex.index == "1"){
										
										if(wCarousel.hasClass('s1')){
											wCarousel.removeClass('s1').addClass('s2');
										}else if(wCarousel.hasClass('s2')){
											wCarousel.removeClass('s2').addClass('s3');
										// }else if(wCarousel.hasClass('s3')){
										// 	wCarousel.removeClass('s3').addClass('s4');
										}else {
											browserHistory.push('/about');
											this.props.fullpageApi.moveSlideLeft();
											$('.info-section').text('about');
											$('.pagination ul').removeClass().addClass('about');
										}
									}else {
										browserHistory.push('/work');
										this.props.fullpageApi.moveSlideLeft();
										$('body').addClass('dark');
										$('.top-nav').removeClass('disabled');
										$('.info-section').addClass('active');
										$('.pagination ul').removeClass().addClass('work');
									}
								}
							}

						}
					}>
						down
					</button>
				</div>
			</div>
		)
	}
}
