import React, { Component } from 'react'
import webDesign from '../assets/web_design.png'
import webDev from '../assets/web_dev.png'
import cms from '../assets/cms.png'
import eCommerce from '../assets/ecommerce.png'
import seo from '../assets/seo.png'
import mobile from '../assets/mobile.png'

export default class Services extends Component {
	render() {
		return (
			<div className="services wrap">
				<div className="padding">
					<div className="serv-wrapper-left serv-wrapper">
						<div className="item">
							<div className="wrap">
								<div className="num">
									<div className="inner">
										<div>01</div>
									</div>
								</div>
								<div className="content">
									<div className="wrap">
										<div className="title-wrap">
											<div className="icon">
												<img src={webDesign} alt="web design"/>
											</div>
											<div className="title">
												Web Design
											</div>
										</div>
										<p>We create layout and design geared to maximise user experience</p>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="wrap">
								<div className="num">
									<div className="inner">
										<div>03</div>
									</div>
								</div>
								<div className="content">
									<div className="wrap">
										<div className="title-wrap">
											<div className="icon">
												<img src={eCommerce} alt="e-Commerce application"/>
											</div>
											<div className="title">
												e-Commerce Application
											</div>
										</div>
										<p>Owning an online shop to market your brand, products and services has never been more mainstream. Ready for your online endeavour? we can help, and promise we won’t bite.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="wrap">
								<div className="num">
									<div className="inner">
										<div>05</div>
									</div>
								</div>
								<div className="content">
									<div className="wrap">
										<div className="title-wrap">
											<div className="icon">
												<img src={seo} alt="search engine optimisation"/>
											</div>
											<div className="title">
												Search Engine Optimisation
											</div>
										</div>
										<p>We make sure your awesome site is crawled by all major search engines and conform with web standard.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="serv-wrapper-right serv-wrapper">
						<div className="item">
							<div className="wrap">
								<div className="num">
									<div className="inner">
										<div>02</div>
									</div>
								</div>
								<div className="content">
									<div className="wrap">
										<div className="title-wrap">
											<div className="icon">
												<img src={webDev} alt="custom web development"/>
											</div>
											<div className="title">
												Custom Web Development
											</div>
										</div>
										<p>Need something more complex and out of the box? We always have ideas and approach to cater for your need, even if we need to build something from ground up, or move a mountain to achieve it.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="wrap">
								<div className="num">
									<div className="inner">
										<div>04</div>
									</div>
								</div>
								<div className="content">
									<div className="wrap">
										<div className="title-wrap">
											<div className="icon">
												<img src={cms} alt="content management system"/>
											</div>
											<div className="title">
												Content Management System
											</div>
										</div>
										<p>We offer a complete yet easily self-learned solution to manage your site at your own ease and time.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="wrap">
								<div className="num">
									<div className="inner">
										<div>06</div>
									</div>
								</div>
								<div className="content">
									<div className="wrap">
										<div className="title-wrap">
											<div className="icon">
												<img src={mobile} alt="mobile app"/>
											</div>
											<div className="title">
												Mobile Application
											</div>
										</div>
										<p>“they have an app for that”, or do they? talk to us and maybe that dream app will not remain a dream any longer. iOS or Android platform both a possibility.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
