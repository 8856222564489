import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import $ from 'jquery'
import homeBanner from '../assets/master_banner.jpg'
import homeBannerMobile from '../assets/master_banner.png'

export default class Home extends Component {
	render() {
		return (
			<div className="slide active home">
				<div className="home-wrapper">
					<div className="left-col">
						<div>
							<h1>We do Digital</h1>
							<p>Spesializing in Digital Design and Development, transforming ideas into digital products</p>
							<button onClick={
								() => {
									this.props.firstState();
									this.props.fullpageApi.moveSlideLeft();
									browserHistory.push('/work');
									$('.top-nav').removeClass('disabled');
									$('body').addClass('dark');
									$('.info-section').addClass('active');
									$('.pagination ul').removeClass().addClass('work');
								} 
							}>What we have worked on</button>
						</div>
					</div>
					<div className="right-col">
						<div>
							<img src={homeBanner} alt="wyel" />
							<img class="mobile" src={homeBannerMobile} alt="wyel" />
						</div>
					</div>
				</div>
			</div>
		)
	}
}
