import React, { Component } from 'react'
import web from '../assets/web.png'
import drupal from '../assets/drupal.png'
// import html5 from '../assets/html5.png'
import umbraco from '../assets/umbraco.png'
import sitecore from '../assets/sitecore.png'
import php from '../assets/php.png'
import wordpress from '../assets/wordpress.png'
import adobe from '../assets/adobexpm.png'
import dotnet from '../assets/dotnet.png'
import java from '../assets/java.png'
import react from '../assets/react.png'
import vue from '../assets/vue.png'
import golang from '../assets/golang.png'

export default class Technologies extends Component {
	render() {
		return (
			<div className="technologies">
				<div className="tech-wrap">
					<h4>rest assured that we are technically capable for you to <br/>focus on your business requirement</h4>
					<div className="tech-list">
						<div className="item">
							<div className="outer">
								<div className="inner">
									<img src={web} alt="html5 css3 js" />
								</div>
							</div>
						</div>
						<div className="item">
							<div className="outer">
								<div className="inner">
									<img src={umbraco} alt="umbraco" />
								</div>
							</div>
						</div>
						<div className="item">
							<div className="outer">
								<div className="inner">
									<img src={sitecore} alt="sitecore" />
								</div>
							</div>
						</div>
						<div className="item">
							<div className="outer">
								<div className="inner">
									<img src={php} alt="umbraco" />
								</div>
							</div>
						</div>
						<div className="item">
							<div className="outer">
								<div className="inner">
									<img src={drupal} alt="drupal" />
								</div>
							</div>
						</div>
						<div className="item">
							<div className="outer">
								<div className="inner">
									<img src={wordpress} alt="wordpress" />
								</div>
							</div>
						</div>
						<div className="item">
							<div className="outer">
								<div className="inner">
									<img src={adobe} alt="adobe experience manager" />
								</div>
							</div>
						</div>
						<div className="item">
							<div className="outer">
								<div className="inner">
									<img src={dotnet} alt=".net" />
								</div>
							</div>
						</div>
						<div className="item">
							<div className="outer">
								<div className="inner">
									<img src={java} alt="java" />
								</div>
							</div>
						</div>
						<div className="item">
							<div className="outer">
								<div className="inner">
									<img src={react} alt="react" />
								</div>
							</div>
						</div>
						<div className="item">
							<div className="outer">
								<div className="inner">
									<img src={vue} alt="vue" />
								</div>
							</div>
						</div>
						<div className="item">
							<div className="outer">
								<div className="inner">
									<img src={golang} alt="go lang" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
