import React, { Component } from 'react'

export default class Contact extends Component {
	render() {
		return (
			<div className="contact-Wrapper">
				<h4>Get in <span>touch</span></h4>
				<p><span>mobile</span> +65 8355 0849 <br/><span>email</span> hello@wyel.com.sg</p>
				{/* <p>Ruko Golden 8 Blok i No. 12 <br/>Gading Serpong, Tanggerang <br/>Indonesia 15810</p> */}
				<p>47 Kallang Pudding Road #06-02, <br/>Crescent @ Kallang, <br/>Singapore 349318</p>
			</div>
		)
	}
}
